$(function () {
    // Accordion
    $('.customize-cookie').click(function () {
        const accordion = $('.cookieModal__accordion');
        accordion.slideToggle(280);
    })

    if(localStorage.getItem("necessaryCookies") == null) {
        localStorage.setItem("consentGranted", "false");
    }

    const functionalCookiesCheckbox = $('input[name="functional-cookies"]');
    const analyticalCookiesCheckbox = $('input[name="analytical-cookies"]');
    const marketingCookiesCheckbox = $('input[name="marketing-cookies"]');

    initialize();

    $('.cookieModal .accept-all-cookie').click(function () {
        $('.cookieModal__accordion').slideToggle(280);
        saveSettings(true, true, true, true);
        hideModalCookie();
    });

    $('.cookieModal .btn-save-choice').click(function () {
        $('.cookieModal__accordion').slideToggle(280);

        const functionalCookiesCheckboxValue = functionalCookiesCheckbox.is(":checked");
        const analyticalCookiesCheckboxValue = analyticalCookiesCheckbox.is(":checked");
        const marketingCookiesCheckboxValue = marketingCookiesCheckbox.is(":checked");

        saveSettings(true, functionalCookiesCheckboxValue, analyticalCookiesCheckboxValue, marketingCookiesCheckboxValue);
        hideModalCookie();
    });

    $('.customize-cookies').click(function () {
        updateModalSettings();
        showModalCookie();
    });

    function initialize() {
        if (localStorage.getItem("consentGranted") === 'false') {
            showModalCookie();
        } else {
            // const necessaryCookies = localStorage.getItem("necessaryCookies") === 'true';
            const functionalCookies = localStorage.getItem("functionalCookies") === 'true';
            const analyticalCookies = localStorage.getItem("analyticalCookies") === 'true';
            const marketingCookies = localStorage.getItem("marketingCookies") === 'true';

            saveSettings(
                true,
                functionalCookies,
                analyticalCookies,
                marketingCookies
            );
        }

        updateModalSettings();
    }

    function updateModalSettings() {
        const consentGranted = localStorage.getItem("consentGranted") === 'true';
        const functionalCookies = localStorage.getItem("functionalCookies") === 'true';
        const analyticalCookies = localStorage.getItem("analyticalCookies") === 'true';
        const marketingCookies = localStorage.getItem("marketingCookies") === 'true';

        if (consentGranted) {
            functionalCookiesCheckbox.prop("checked", functionalCookies);
            analyticalCookiesCheckbox.prop("checked", analyticalCookies);
            marketingCookiesCheckbox.prop("checked", marketingCookies);
        }
    }

    function saveSettings(
        necessaryCookies = false,
        functionalCookies = false,
        analyticalCookies = false,
        marketingCookies = false
    ) {
        /*
        * UPDATE in local storage
        * */
        localStorage.setItem("consentGranted", "true");
        localStorage.setItem("necessaryCookies", necessaryCookies.toString());
        localStorage.setItem("functionalCookies", functionalCookies.toString());
        localStorage.setItem("analyticalCookies", analyticalCookies.toString());
        localStorage.setItem("marketingCookies", marketingCookies.toString());

        /*
        * UPDATE in GTM
        * */
        function gtag() { dataLayer.push(arguments); }
        gtag('consent', 'update', {
            // necessaryCookies
            personalization_storage: necessaryCookies ? 'granted' : 'denied',
            security_storage: necessaryCookies ? 'granted' : 'denied',

            // functionalCookies
            functionality_storage: functionalCookies ? 'granted' : 'denied',

            // analyticalCookies
            analytics_storage: analyticalCookies ? 'granted' : 'denied',

            // marketingCookies
            ad_storage: marketingCookies ? 'granted' : 'denied',
            ad_user_data: marketingCookies ? 'granted' : 'denied',
            ad_personalization: marketingCookies ? 'granted' : 'denied'
        });
        dataLayer.push({event: 'cookie_consent_update'});
    }

    function showModalCookie() {
        $('.cookieModal').modal({backdrop: 'static', keyboard: false}, 'show');
    }

    function hideModalCookie() {
        $('.cookieModal').modal('hide');
    }
});
